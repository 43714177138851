<template>
  <div class="dizme_tm_all_wrap" data-magic-cursor="show">
    <MobileHeaderVue />
    <HeaderVue />
    <HomePage />
    <!-- HERO -->

    <!-- ABOUT -->
    <AboutMusic />
    <!-- /ABOUT -->
    
    <!-- PORTFOLIO -->
    <PortfolioPhoto />
    <!-- /PORTFOLIO -->

    <!-- VLog -->
   <Vlog /> 
    <!-- /NEWS -->

    <!-- COPYRIGHT -->
    <CopyrightComponent />
    <!-- /COPYRIGHT -->

    <ScrollTopVue />
  </div>
</template>

<script>
import HeaderVue from "@/components/layout/Header.vue";
import MobileHeaderVue from "@/components/layout/MobileHeader.vue";
import ScrollTopVue from "@/components/layout/ScrollTop.vue";
import { activeSkillProgress } from "@/utilits";
import AboutMusic from "../components/AboutMusic.vue";
import CopyrightComponent from "../components/CopyrightComponent.vue";
import HomePage from "../components/HomePage.vue";
import Vlog from "@/components/Vlog.vue";
import PortfolioPhoto from "../components/PortfolioPhoto.vue";




export default {
  name: "IndexView",
  components: {
    HomePage,
    AboutMusic,
    PortfolioPhoto,
    Vlog,
    CopyrightComponent,
    HeaderVue,
    MobileHeaderVue,
    ScrollTopVue,
  },
  mounted() {
    window.addEventListener("scroll", activeSkillProgress);
  },
};
</script>
