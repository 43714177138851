<template>
  <div class="dizme_tm_section" id="vlog">
    <div class="dizme_tm_news">
      <div class="container">
        
        <div class="dizme_tm_main_title" data-align="center">
          <span>From My Blog</span>
          <h3>Our Recent Updates, Blog, Tips, Tricks &amp; More</h3>
          
        </div>
        
          
        
        <div class="news_list">
          <ul>
            <li class="wow fadeInUp" data-wow-duration="1s">
              <div class="list_inner">
                <div class="image">
                  <img src="/img/thumbs/42-29.jpg" alt="" />
                  <div class="main" data-img-url="/img/news/1.jpg"></div>
                  
                  <a
                    class="dizme_tm_full_link"
                    href="#"
                    @click.prevent="active = 1"
                  ></a>
                </div>
                <div class="details">
                  <span class="category"
                    ><a href="#" @click.prevent="active = 1"
                      >Festival</a
                    ></span
                  >
                  <h3 class="title">
                    <a href="#" @click.prevent="active = 1"
                      >Gudipadwa Girgaon Rally: A Colorful Maharashtrian New Year Celebration</a
                    >
                  </h3>
                </div>
              </div>
            </li>
            <li
              class="wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div class="list_inner">
                <div class="image">
                  <img src="/img/thumbs/42-29.jpg" alt="" />
                  <div class="main" data-img-url="/img/news/2.jpg"></div>
                  <div class="date">
                    <h3>23</h3>
                    <span>Dec</span>
                  </div>
                  <a
                    class="dizme_tm_full_link"
                    href="#"
                    @click.prevent="active = 2"
                  ></a>
                </div>
                <div class="details">
                  <span class="category"
                    ><a href="#" @click.prevent="active = 2">Branding</a></span
                  >
                  <h3 class="title">
                    <a href="#" @click.prevent="active = 2"
                      >How to be appreciated for your hard work as a
                      developer</a
                    >
                  </h3>
                </div>
              </div>
            </li>
            <li
              class="wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
            >
              <div class="list_inner">
                <div class="image">
                  <img src="/img/thumbs/42-29.jpg" alt="" />
                  <div class="main" data-img-url="/img/news/3.jpg"></div>
                  <!--<div class="date">
                    <h3>23</h3>
                    <span>Dec</span>
                  </div> -->
                  <a
                    class="dizme_tm_full_link"
                    href="#"
                    @click.prevent="active = 3"
                  ></a>
                </div>
                <div class="details">
                  <span class="category"
                    ><a href="#" @click.prevent="active = 3"
                      >Social Media</a
                    ></span
                  >
                  <h3 class="title">
                    <a href="#" @click.prevent="active = 3"
                      >How designers and developers can collaborate better</a
                    >
                  </h3>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="brush_1 wow zoomIn" data-wow-duration="1s">
        <img src="/img/brushes/news/1.png" alt="" />
      </div>
       <div class="brush_2 wow zoomIn" data-wow-duration="1s">
        <img src="/img/brushes/news/2.png" alt="" />
      </div> 
    </div>
  </div>
  <div :class="active == 1 ? '' : 'news_hidden_details'">
    <ModalBox :close="close">
      <div class="news_popup_informations">
        <iframe width="100%" height="400" src="https://www.youtube.com/embed/NqpufFGoZaU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <!--
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/news/1.jpg"
            style="background-image: url('img/news/1.jpg')"
          ></div>
        </div> -->
        <div class="details">
          <h3>Gudipadwa Girgaon Rally: A Colorful Maharashtrian New Year Celebration</h3>
          <span><a href="#">Festival</a></span>
          <div></div>
        </div>
        <div class="dizme_tm_button wow fadeInUp" data-wow-duration="1s">
              <a class="anchor" href="https://youtu.be/NqpufFGoZaU"><span>Cant Watch Video- Tap here</span></a>
            </div>
        <div class="text">
          <p>
            As the beauty of spring begins to paint our world, Maharashtra bursts into celebration with the festive spirit of Gudipadwa, the traditional Maharashtrian New Year. This year, my dear friend Abha and I were lucky enough to dive right into these festivities for the very first time. We are thrilled to bring you along on our adventure through this vibrant celebration in our vlog.
          </p>
          <p>
            Gudipadwa, also known as Marathi New Year or Chaitra Shukla Pratipada, marks the beginning of the Maharashtrian calendar. It falls on the first day of the Chaitra month, typically in March or April, signifying the rebirth of nature after the long winter. The festival gets its name from the 'Gudi,' a colorful flagpole adorned with neem leaves, flowers, and cloth, traditionally hoisted on this day.
          </p>
          <p>
            Our journey led us to Girgaon, a bustling neighborhood in the heart of Mumbai renowned for its lively Gudipadwa celebrations. Girgaon's historical allure seamlessly blended with the festivities, creating an atmosphere filled with joy and anticipation. The streets were decked with intricate rangoli designs, and every nook whispered tales of tradition and culture.
          </p>
        
      
          <p>
            The highlight of our visit was the spectacular Gudipadwa rally that wound its way through Girgaon's narrow lanes. The rally was a burst of colors and sounds, with participants of all ages proudly showcasing their Maharashtrian heritage. From traditional attire to colorful turbans and the melodious beats of folk music, every part of the rally was a reflection of Maharashtra's rich culture.
          </p>
          <p>
            No Maharashtrian celebration is complete without a gastronomic adventure. Abha and I couldn't resist savoring local delicacies, from the delectable 'Puran Poli' to the irresistible 'Misal Pav.' As first-time visitors, every bite was a delightful surprise, and the flavors danced on our taste buds, leaving us wanting more.
          </p>
          <p>
            However, beyond the colors, dances, and delicious food, what truly touched our hearts was the warmth of the people we encountered. The rally participants, the friendly locals who shared their stories, and the cheerful children who joined in the festivities all radiated unity and pride in their culture.
          </p>
          <p>
            As we draw this blog post to a close, Abha and I are brimming with gratitude for the unique opportunity to experience the Gudipadwa Girgaon Rally as first-time attendees. It served as a vivid reminder of the remarkable diversity and richness of our Indian culture, highlighting the enduring spirit of celebration that unites us all.

            We invite you to watch our vlog, where you can join us on our delightful journey through Girgaon's heart. Until next time, continue celebrating life's vibrant moments, and may the year ahead be as colorful as a Gudipadwa flag swaying joyfully in the breeze.
          </p>
        </div>
        <p>.</p>
        <div class="dizme_tm_button wow fadeInUp" data-wow-duration="1s">
              <a class="anchor" href="https://youtu.be/NqpufFGoZaU"><span>Cant Watch Video - Tap here</span></a>
            </div>
      </div>
    </ModalBox>
  </div>
  <div :class="active == 2 ? '' : 'news_hidden_details'">
    <ModalBox :close="close">
      <div class="news_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/news/2.jpg"
            style="background-image: url('img/news/2.jpg')"
          ></div>
        </div>
        <div class="details">
          <h3>How to be appreciated for your hard work as a developer</h3>
          <span><a href="#">Branding</a></span>
          <div></div>
        </div>
        <div class="text">
          <p>
            Dizme is a leading web design agency with an award-winning design
            team that creates innovative, effective websites that capture your
            brand, improve your conversion rates, and maximize your revenue to
            help grow your business and achieve your goals.
          </p>
          <p>
            In today’s digital world, your website is the first interaction
            consumers have with your business. That's why almost 95 percent of a
            user’s first impression relates to web design. It’s also why web
            design services can have an immense impact on your company’s bottom
            line.
          </p>
          <p>
            That’s why more companies are not only reevaluating their website’s
            design but also partnering with Kura, the web design agency that’s
            driven more than $2.4 billion in revenue for its clients. With over
            50 web design awards under our belt, we're confident we can design a
            custom website that drives sales for your unique business.
          </p>
        </div>
      </div>
    </ModalBox>
  </div>
  <div :class="active == 3 ? '' : 'news_hidden_details'">
    <ModalBox :close="close">
      <div class="news_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/news/3.jpg"
            style="background-image: url('img/news/3.jpg')"
          ></div>
        </div>
        <div class="details">
          <h3>How designers and developers can collaborate better</h3>
          <span><a href="#">Social Media</a></span>
          <div></div>
        </div>
        <div class="text">
          <p>
            Dizme is a leading web design agency with an award-winning design
            team that creates innovative, effective websites that capture your
            brand, improve your conversion rates, and maximize your revenue to
            help grow your business and achieve your goals.
          </p>
          <p>
            In today’s digital world, your website is the first interaction
            consumers have with your business. That's why almost 95 percent of a
            user’s first impression relates to web design. It’s also why web
            design services can have an immense impact on your company’s bottom
            line.
          </p>
          <p>
            That’s why more companies are not only reevaluating their website’s
            design but also partnering with Kura, the web design agency that’s
            driven more than $2.4 billion in revenue for its clients. With over
            50 web design awards under our belt, we're confident we can design a
            custom website that drives sales for your unique business.
          </p>
        </div>
      </div>
    </ModalBox>
  </div>
</template>

<script>
import ModalBox from "./popup/ModalBox.vue";
export default {
  name: "NewsComponent",
  components: { ModalBox },
  data() {
    return {
      active: 0,
    };
  },
  methods: {
    close() {
      this.active = 0;
    },
  },
};
</script>
