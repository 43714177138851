<template>
  <div class="dizme_tm_section" id="home">
    <div class="dizme_tm_hero">
      <div
        class="background"
        :data-img-url="`/img/slider/${dark ? 2 : 1}.jpg`"
      ></div>
      <div class="container">
        <div class="content">
          <div class="details">
            <div class="hello">
              <h3 class="orangeText">Hello, I'm</h3>
            </div>
            <div class="name">
              <h3>Prasad Bhoi</h3>
            </div>
            <div class="job">
              <p>
                By Profession a <span class="greenText">Engineer </span> From
                <span class="purpleText">Mumbai</span>
              </p>
            </div>
            <div class="text">
              <p>
                My heart lies in the harmonium's music, capturing life's candid snapshots, and expressing my thoughts through vlogs. 
              </p>
            </div>
            <div class="button">
              <div class="dizme_tm_button">
                <a class="anchor" href="#aboutmusic"><span>About Me</span></a>
              </div>
              <div class="social">
                <ul>
                  <li>
                    <a href="#"><i class="icon-instagram-3"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="icon-youtube-1"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="icon-facebook-1"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="icon-twitter-1"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="icon-linkedin-1"></i></a>
                  </li>
                
                </ul>
              </div>
            </div>
          </div>
          <div class="avatar">
            <div class="image">
              <img src="/img/slider/avatar.png" alt="" />
              
            </div>
          </div>
        </div>
      </div>
      <div class="dizme_tm_down">
        <a class="anchor" href="#process" v-html="dark ? mouseDark : mouse">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ai, figma, mouse, mouseDark, ps } from "../svg";
export default {
  name: "HomePage",
  data() {
    return { ai, ps, figma, mouse, mouseDark };
  },
  props: {
    dark: { type: Boolean },
  },
};
</script>
