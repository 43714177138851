<template>
  <div class="dizme_tm_section" id="PortfolioPhoto">
    <div class="dizme_tm_portfolio">
      <div class="container">
        <div class="dizme_tm_main_title" data-align="center">
          <span>About Photography</span>
          <h3>Through My lens</h3>
          <p>
            Candid photography, for me, is like a magical time machine. It's the art of capturing moments, emotions, and stories, turning them into everlasting memories. Through my camera, I try to narrate tales - tales of India's lively festivals like Gudipadwa and Ganpati, and the simple yet profound beauty of daily life. Whether it's the warm smiles in a bustling market, or the serene life in a village, each click is an invitation to explore India's heart. Every photograph is a memory, an emotion, and a celebration of our culture and traditions.
          </p>
        </div>
        <div class="portfolio_filter">
          <ul>
             <li>
              <a
                class="c-pointer"
                :class="activeNav === '1' ? 'current' : ''"
                @click.prevent="() => (this.activeNav = '1')"
                data-filter="*"
                >All</a
              >
            </li> 
            <li>
              <a
                class="c-pointer"
                :class="activeNav === '2' ? 'current' : ''"
                @click.prevent="() => (this.activeNav = '2')"
                data-filter=".detail"
                >People</a
              >
            </li>
           <!--  <li>
              <a
                class="c-pointer"
                :class="activeNav === '3' ? 'current' : ''"
                @click.prevent="() => (this.activeNav = '3')"
                data-filter=".detail"
                >Nature</a
              >
            </li>
            <li>
              <a
                class="c-pointer"
                :class="activeNav === '4' ? 'current' : ''"
                @click.prevent="() => (this.activeNav = '4')"
                data-filter=".detail"
                >People</a
              >
            </li>
            <li>
              <a
                class="c-pointer"
                :class="activeNav === '5' ? 'current' : ''"
                @click.prevent="() => (this.activeNav = '5')"
                data-filter=".detail"
                >Manmade Objects</a
              >
            </li>
            <li>
              <a
                class="c-pointer"
                :class="activeNav === '6' ? 'current' : ''"
                @click.prevent="() => (this.activeNav = '6')"
                data-filter=".detail"
                >Traval</a
              >
            </li> -->
          </ul>
        </div>
        <div class="dizme_tm_portfolio_titles"></div>
        <div class="portfolio_list wow fadeInUp" data-wow-duration="1s">
          <ul class="gallery_zoom grid">
            
            <li class="detail grid-item">
              <div class="inner">
                <div
                  class="entry dizme_tm_portfolio_animation_wrap"
                  data-title="Varkari Farmer"
                  data-category="Click on image for more details"
                >
                  <a
                    class="portfolio_popup"
                    href="#"
                    @click.prevent="active = 1"
                  >
                    <img src="/img/thumbs/42-56.jpg" alt="" />
                    <div class="main" data-img-url="/img/portfolio/v1.jpg"></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Varkari Farmer</h3>
                  <span>Click on image for more details</span>
                </div>
              </div>
            </li>

            <li class="detail grid-item">
              <div class="inner">
                <div
                  class="entry dizme_tm_portfolio_animation_wrap"
                  data-title="School Kids"
                  data-category="Click for More Details"
                >
                  <a
                    class="portfolio_popup"
                    href="#"
                    @click.prevent="active = 2"
                  >
                    <img src="/img/thumbs/42-29.jpg" alt="" />
                    <div class="main" data-img-url="/img/portfolio/h1.jpg"></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>School Kids</h3>
                  <span>Click on image for more details</span>
                </div>
              </div>
            </li>
            
            <li class="detail grid-item">
              <div class="inner">
                <div
                  class="entry dizme_tm_portfolio_animation_wrap"
                  data-title="Abha"
                  data-category="Click on image for more details"
                >
                  <a
                    class="portfolio_popup"
                    href="#"
                    @click.prevent="active = 3"
                  >
                    <img src="/img/thumbs/42-56.jpg" alt="" />
                    <div class="main" data-img-url="/img/portfolio/v6.jpg"></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Abha</h3>
                  <span>Click on image for more details</span>
                </div>
              </div>
            </li>

            <li class="detail grid-item">
              <div class="inner">
                <div
                  class="entry dizme_tm_portfolio_animation_wrap"
                  data-title="Preeti"
                  data-category="Click for More Details"
                >
                  <a
                    class="portfolio_popup"
                    href="#"
                    @click.prevent="active = 4"
                  >
                    <img src="/img/thumbs/42-29.jpg" alt="" />
                    <div class="main" data-img-url="/img/portfolio/h4.jpg"></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Preeti</h3>
                  <span>Click on image for more details</span>
                </div>
              </div>
            </li>
            
            

            

          </ul>
        </div>
      </div>
      <div class="brush_1 wow zoomIn" data-wow-duration="1s">
        <img src="/img/brushes/portfolio/1.png" alt="" />
      </div>
      <div class="brush_2 wow fadeInRight" data-wow-duration="1s">
        <img src="/img/brushes/portfolio/2.png" alt="" />
      </div>
    </div>
  </div>
  <MagnificPopUpVue
    :modal="modal"
    :close="closeModal"
    :name="name"
    :src="src"
  />
  
  <!-- People Vertical-->

  <div :class="`${active === 1 ? '' : 'hidden_content'}`">
    <ModalBox :close="close">
      <div class="popup_details">
        
        <div class="portfolio_main_title">
          <h3>An unbreakable bond with God Vitthala</h3>
          <span><a href="#">More below...</a></span>
          <div></div>
        </div>
        <div class="main_details">
          <div class="textbox">
            <div class="top_image">
               <img src="img/thumbs/42-56.jpg" alt="" />
               <div
                  class="main"
                 data-img-url="img/portfolio/v1.jpg"
                  style="background-image: url('img/portfolio/v1.jpg')">
               </div>
            </div>
            
          </div>
          <div class="detailbox">
            <ul>
              <li>
                <span class="first">Person</span>
                <span>Varkari Farmer</span>
              </li>
              <li>
                <span class="first">Posted</span>
                <span><a href="https://www.instagram.com/p/ClQwzXYjBwk/">Click to visit Instagram</a></span>
              </li>
              <li>
                <span class="first">Shot on </span>
                <span>March 07, 2021</span>
              </li>
              <li>
                <span class="first">Spot </span>
                <span>Osmanabad, Maharashtra India</span>
              </li>
              <!-- <li>
                <span class="first">Her Profile</span>
                <span><a href="https://www.instagram.com/_abhaaaaa_/">Click to visit Profile</a></span>
              </li> -->
            </ul>
          </div>
        </div>

        <div class="textbox">
            
            <h4>
              This picture shows a Varkari farmer, a person deeply devoted to God Vitthala.
            </h4>
            <p>
            
              Despite the challenges of farming, he finds strength in his faith. In his weathered hands and sincere eyes, you can see how much his faith means to him. For him, farming is not just a job; it's a spiritual journey. Every plowed field, every seed sown, and every harvest reaped is an offering to the divine.

              This image tells a story of determination and unwavering belief. It celebrates those who, even when faced with difficulties, draw strength from their faith. It reminds us that our spirit can shine even in tough times when we hold onto our faith.
            </p>
          </div>

        
      </div>
    </ModalBox>
  </div>

  <div :class="`${active === 3 ? '' : 'hidden_content'}`">
    <ModalBox :close="close">
      <div class="popup_details">
        
        <div class="portfolio_main_title">
          <h3>She is beautiful, versatile and pure.</h3>
          <span><a href="#">More below...</a></span>
          <div></div>
        </div>
        <div class="main_details">
          <div class="textbox">
            <div class="top_image">
               <img src="img/thumbs/42-56.jpg" alt="" />
               <div
                  class="main"
                 data-img-url="img/portfolio/v6.jpg"
                  style="background-image: url('img/portfolio/v6.jpg')">
               </div>
            </div>
            
          </div>
          <div class="detailbox">
            <ul>
              <li>
                <span class="first">Radiant Beauty </span>
                <span>Abha</span>
              </li>
              <li>
                <span class="first">Posted</span>
                <span><a href="https://www.instagram.com/p/Clk1Mort2ZQ/">Click to visit Instagram</a></span>
              </li>
              <li>
                <span class="first">Shot on </span>
                <span>March 07, 2021</span>
              </li>
              <li>
                <span class="first">Spot </span>
                <span>Mumbai, India</span>
              </li>
              <li>
                <span class="first">Her Profile</span>
                <span><a href="https://www.instagram.com/_abhaaaaa_/">Click to visit Profile</a></span>
              </li>
            </ul>
          </div>
        </div>

        <div class="textbox">
            
            <h4>
              She is beautiful, versatile and pure.
            </h4>
            <p>
              She is beautiful, versatile and pure. She is way younger than me and yet I learn so much from her. I have seen Mumbai, the Maya Nagari distracting many and hence I am amazed with her grounded and focused demeanor. Unlike many of her age, she has a vision for life. That's what I love about her.
            </p>
          </div>

        
      </div>
    </ModalBox>
  </div>

  <!-- People Horizontal -->
  <div :class="`${active === 2 ? '' : 'hidden_content'}`">
    <ModalBox :close="close">
      <div class="popup_details">
        
        <div class="portfolio_main_title">
          <h3>Glimpse of the innocence and resilience of school-going kids</h3>
          <span><a href="#">More below...</a></span>
          <div></div>
        </div>
        <div class="main_details">
          <div class="textbox">
            <div class="top_image">
               <img src="img/thumbs/42-29.jpg" alt="" />
               <div
                  class="main"
                 data-img-url="img/portfolio/h1.jpg"
                  style="background-image: url('img/portfolio/h1.jpg')">
               </div>
            </div>
            
          </div>
          <div class="detailbox">
            <ul>
              <li>
                <span class="first">In the Snap </span>
                <span>School Kids</span>
              </li>
              <li>
                <span class="first">Posted</span>
                <span><a href="https://www.instagram.com/p/ClF783grNKy/">Click to visit Instagram</a></span>
              </li>
              <li>
                <span class="first">Shot on </span>
                <span>March 07, 2021</span>
              </li>
              <li>
                <span class="first">Spot </span>
                <span>Village Near, Washim, Maharashtra, India</span>
              </li>
             <!--  <li>
                <span class="first">Her Profile</span>
                <span><a href="https://www.instagram.com/_preetipawar/">Click to visit Profile</a></span>
              </li> -->
            </ul>
          </div>
        </div>

        <div class="textbox">
            
            <h4>
              In this heartwarming image, we catch a glimpse of the innocence and resilience of school-going kids.
            </h4>
            <p>
               The backdrop is a rural school that had closed its doors for the summer vacation. Yet, the spirit of learning and play knows no bounds.

              On a sunny day, these children found joy in the simplicity of life by engaging in a game of cricket within the confines of their school compound. Their laughter and enthusiasm echoed through the empty classrooms, a testament to the camaraderie and bonds of friendship they shared.

              As fate would have it, our arrival interrupted their impromptu game. However, what struck us was their warm and welcoming demeanor. Instead of annoyance, curiosity filled their eyes. These young souls, with faces lit up by hope and dreams, began to share their stories.

              What we heard was both touching and eye-opening. They spoke of the dilapidated condition of the only school in their village. It was a place where their aspirations took flight, yet one that had been forgotten by many. One of the kids, with an earnest tone, expressed a sentiment that resonated deeply: 'At least you have come to see our school. Otherwise, who comes to see the school?'

              In this snapshot, we witness not just a game of cricket but the aspirations of a generation. It reminds us that even amidst challenges, the spirit of learning and the pursuit of knowledge endure. These children, with their boundless optimism, inspire us to cherish and support the institutions that shape their futures.
            </p>
          </div>

        
      </div>
    </ModalBox>
  </div>
  
  <div :class="`${active === 4 ? '' : 'hidden_content'}`">
    <ModalBox :close="close">
      <div class="popup_details">
        
        <div class="portfolio_main_title">
          <h3>I was curious to meet this passionate young lady</h3>
          <span><a href="#">More below...</a></span>
          <div></div>
        </div>
        <div class="main_details">
          <div class="textbox">
            <div class="top_image">
               <img src="img/thumbs/42-29.jpg" alt="" />
               <div
                  class="main"
                 data-img-url="img/portfolio/h4.jpg"
                  style="background-image: url('img/portfolio/h4.jpg')">
               </div>
            </div>
            
          </div>
          <div class="detailbox">
            <ul>
              <li>
                <span class="first">Radiant Beauty </span>
                <span>Preeti</span>
              </li>
              <li>
                <span class="first">Posted</span>
                <span><a href="https://www.instagram.com/p/ClnCfpLyLZB/">Click to visit Instagram</a></span>
              </li>
              <li>
                <span class="first">Shot on </span>
                <span>March 07, 2021</span>
              </li>
              <li>
                <span class="first">Spot </span>
                <span>Washim, Maharashtra, India</span>
              </li>
              <li>
                <span class="first">Her Profile</span>
                <span><a href="https://www.instagram.com/_preetipawar/">Click to visit Profile</a></span>
              </li>
            </ul>
          </div>
        </div>

        <div class="textbox">
            
            <h4>
              I was curious to meet this passionate young lady
            </h4>
            <p>
              I was curious to meet this passionate young lady. An engineer by profession, she came from the city of Pune and yet chose to work in small district like Washim for a year. All alone, from a metro city to a district place not having a theatre or a fastfood joint or even a library. Quite a courageous decision! She not only stayed there for a year but did her best to support the district administration in implementing various developmental initiatives. This image was captured during our visit to one such initiative.
            </p>
          </div>

        
      </div>
    </ModalBox>
  </div>  

  


  
  
  

  

</template>

<script>
import MagnificPopUpVue from "./popup/MagnificPopUp.vue";
import ModalBox from "./popup/ModalBox.vue";
export default {
  name: "PortfolioComponent",
  data() {
    return {
      activeNav: "1", // props
      name: "",
      src: "",
      modal: false,
      // Content
      active: 0,
    };
  },
  mounted() {
    let Isotope = require("isotope-layout");
    setTimeout(() => {
      let iso = new Isotope(".gallery_zoom", {
        itemSelector: ".grid-item",
        //    layoutMode: "fitRows",
        percentPosition: true,
        masonry: {
          columnWidth: ".grid-item",
        },
        animationOptions: {
          duration: 750,
          easing: "linear",
          queue: false,
        },
      });
      let filterFns = {
        // show if number is greater than 50
        numberGreaterThan50: function (itemElem) {
          let number = itemElem.querySelector(".number").textContent;
          return parseInt(number, 10) > 50;
        },
        // show if name ends with -ium
        ium: function (itemElem) {
          let name = itemElem.querySelector(".name").textContent;
          return name.match(/ium$/);
        },
      };
      // bind filter button click filter main funtion
      let filtersElem = document.querySelector(".portfolio_filter ul");
      filtersElem.addEventListener("click", function (event) {
        if (event.target.getAttribute("data-filter")) {
          let filterValue = event.target.getAttribute("data-filter");
          filterValue = filterFns[filterValue] || filterValue;
          iso.arrange({ filter: filterValue });
        }
      });
    }, 1000);
  },
  methods: {
    onClick(name, src) {
      this.name = name;
      this.src = src;
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
      this.name = "";
      this.src = "";
    },
    close() {
      this.active = 0;
    },
  },
  components: { MagnificPopUpVue, ModalBox },
};
</script>
