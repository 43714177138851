<template>
  <div class="dizme_tm_section" id="aboutmusic">
    <div class="dizme_tm_about">
      <div class="container">
        <div class="wrapper">
          <div class="left">
            <div class="image">
              <img :src="`/img/about/${dark ? 2 : 1}.jpg`" alt="" />
              <div class="numbers year">
                <div class="wrapper">
                  <h3>
                    <VueJsCounter end="1878"></VueJsCounter>
                  </h3>
                  <span class="name">K<br />Subscribers</span>
                </div>
              </div>
              <div class="numbers project">
                <div class="wrapper">
                  <h3 style="display: flex">
                    <VueJsCounter as="span" end="109"></VueJsCounter>
                  </h3>
                  <span class="name">Music<br />Videos</span>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="title wow fadeInUp" data-wow-duration="1s">
              <span>About Music</span>
              <h3>Speaking a universal language</h3>
            </div>
            <div class="text wow fadeInUp" data-wow-duration="1s">
              <p>
                Playing the harmonium feels like speaking a universal language that knows no boundaries; it's my way of connecting with people everywhere. As time rolled on, I decided to mix the traditional melodies with digital magic, creating harmonium covers that add a modern twist to this age-old art. My music is about sharing stories, feelings, and the sheer delight of expressing myself. Join me on this harmonious expedition through my YouTube channel.
              </p>
            </div>
            <div class="dizme_tm_button wow fadeInUp" data-wow-duration="1s">
              <a class="anchor" href="#contact"><span>Harmonium With Prasad</span></a>
            </div>
          </div>
        </div>
      </div>
      <div class="brush_1 wow fadeInLeft" data-wow-duration="1s">
        <img src="/img/brushes/about/1.png" alt="" />
      </div>
      <div class="brush_2 wow fadeInRight" data-wow-duration="1s">
        <img src="/img/brushes/about/2.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import VueJsCounter from "vue-js-counter";
export default {
  name: "AboutComponent",
  components: { VueJsCounter },
  props: {
    dark: { type: Boolean },
  },
};
</script>
